import React, { useEffect, useState } from 'react';
import ScaleText from 'react-scale-text';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchTournaments } from '../../features/tournaments/actionCreators';
import { TypeError } from '../../types/enums';
import { createTournament, duplicateTournament, safeDeleteTournament } from '../../apis/tournament';
import {
    clearTournamentsNotifications,
    tournamentsFetchingError,
} from '../../features/tournaments/tournamentsSlice';
import { CustomButton, CustomSpinner } from '../../components';
import { clearClass } from '../../features/class/classSlice';
import { Notification } from '../../hooks/useNotification';
import {
    getIsLoading,
    getOrganizationStats,
    useGetCurrentOrganization,
} from '../../features/organizations/organizationsSelectors';

import './CreativeDashboard.scss';
import { getReadableDate } from '../../utils/getStringDate';
import { AddIcon } from '../../assets/icons/AddIcon';
import { SettingsIcon } from '../../assets/icons/SettingsIcon';
import { selectTournaments } from '../../features/tournaments/tournamentsSelectors';
import { Alert, Button, Dropdown, Modal } from 'react-bootstrap';
import { Copy, ThreeDotsVertical, Trash } from 'react-bootstrap-icons';
import { useMutation } from '@tanstack/react-query';

export const CreativeDashboard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const deleteMutation = useMutation({
        mutationFn: (id: number) => {
            return safeDeleteTournament(id);
        },
        onSuccess: () => {
            // queryClient.invalidateQueries({ queryKey: ['tournaments'] });
            if (currentOrganization) {
                dispatch(fetchTournaments(currentOrganization.id));
            }
            Notification(TypeError.success, t('tournament-deleted'));
            closeConfirmationDialogue();
        },
    });
    // When set, shows a delete confirmation popup
    const [deleteConfirmationId, setDeleteConfirmationId] = useState<number | null>(null);

    const closeConfirmationDialogue = () => setDeleteConfirmationId(null);

    const currentOrganization = useGetCurrentOrganization();
    const organizationIsLoading = useAppSelector(getIsLoading);

    if (!organizationIsLoading && !currentOrganization) {
        history.push('/communities');
    }

    const { data, isLoading, error, message } = useAppSelector(selectTournaments);

    const stats = useAppSelector(getOrganizationStats);

    const prefix = 'creative-';

    useEffect(() => {
        dispatch(clearClass());
        currentOrganization && dispatch(fetchTournaments(currentOrganization.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganization?.id]);

    useEffect(() => {
        dispatch(clearTournamentsNotifications());
    }, [dispatch, error, message]);

    if (isLoading) {
        return (
            <div className="px-lg-5 px-sm-2 d-flex flex-grow-1 flex-column">
                <CustomSpinner />
            </div>
        );
    }

    if (error) {
        Notification(TypeError.error, error);
    }

    if (message) {
        Notification(TypeError.success, t(message));
    }

    const goToTournament = (id: number | string) =>
        currentOrganization &&
        history.push(`/community/${currentOrganization.id}/tournament/${id}`);

    const createNewTournament = async () => {
        if (!currentOrganization) return;

        try {
            const tournament = await createTournament(currentOrganization.id);
            goToTournament(tournament.id);
        } catch (e: any) {
            dispatch(tournamentsFetchingError(e.message));
            history.push(`/community/${currentOrganization.id}`);
        }
    };

    const copyTournament = async (id: string) => {
        if (!currentOrganization) return;

        try {
            const tournament = await duplicateTournament(+id);
            goToTournament(tournament.id);
        } catch (error: any) {
            dispatch(tournamentsFetchingError(error.message));
            history.push(`/community/${currentOrganization.id}`);
        }
    };

    const deleteTournament = (id: string) => {
        // Open up confirm delete popup
        if (!currentOrganization) return;

        setDeleteConfirmationId(+id);
    };

    const confirmDeleteTournament = () => {
        if (!deleteConfirmationId) return;

        deleteMutation.mutate(deleteConfirmationId);
    };

    return (
        <>
            <Modal show={deleteConfirmationId !== null} onHide={closeConfirmationDialogue}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete tournament</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this tournament? This action cannot be undone.
                </Modal.Body>
                {deleteMutation.error && (
                    <Alert variant="danger">
                        {/* @ts-expect-error this type is much more broad */}
                        {deleteMutation.error?.response?.data?.message ??
                            deleteMutation.error.message}
                    </Alert>
                )}
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeConfirmationDialogue}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmDeleteTournament}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="creative-dashboard">
                {currentOrganization && (
                    <div className="creative-dashboard__community-name">
                        {currentOrganization.name}
                    </div>
                )}
                {currentOrganization && (
                    <div className="creative-dashboard__community-settings">
                        <div className="creative-dashboard__actionButtons">
                            <CustomButton
                                className="creative-dashboard__community-settings-button"
                                btnText={t(prefix + 'settings')}
                                onClick={() =>
                                    history.push(`/community/${currentOrganization.id}/settings`)
                                }
                            >
                                <SettingsIcon h="22" w="22" />
                            </CustomButton>

                            <CustomButton
                                className="creative-dashboard__community-settings-button"
                                btnText={t(prefix + 'createTournament')}
                                onClick={createNewTournament}
                            >
                                <AddIcon h="22" w="22" />
                            </CustomButton>
                        </div>
                    </div>
                )}

                {stats && (
                    <div className="container-fluid">
                        {/* <div className="creative-dashboard__section-title">{t(prefix + 'stats')}</div> */}
                        <div className="creative-dashboard__stats">
                            <div className="creative-dashboard__stats-item">
                                <div className="creative-dashboard__stats-quantity">
                                    <ScaleText maxFontSize={32}>{stats.totalFollowers}</ScaleText>
                                </div>
                                <div className="creative-dashboard__stats-value-name">
                                    {t(prefix + 'statsFollowers')}
                                </div>
                            </div>
                            <div className="creative-dashboard__stats-item">
                                <div className="creative-dashboard__stats-quantity">
                                    <ScaleText maxFontSize={32}>{stats.totalTournaments}</ScaleText>
                                </div>
                                <div className="creative-dashboard__stats-value-name">
                                    {t(prefix + 'statsTournaments')}
                                </div>
                            </div>
                            <div className="creative-dashboard__stats-item">
                                <div className="creative-dashboard__stats-quantity">
                                    <ScaleText maxFontSize={32}>{stats.totalTickets}</ScaleText>
                                </div>
                                <div className="creative-dashboard__stats-value-name">
                                    {t(prefix + 'statsTickets')}
                                </div>
                            </div>
                            <div className="creative-dashboard__stats-item">
                                <div className="creative-dashboard__stats-quantity">
                                    <ScaleText maxFontSize={32}>{stats.totalMatches}</ScaleText>
                                </div>
                                <div className="creative-dashboard__stats-value-name">
                                    {t(prefix + 'statsMatches')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <div className="creative-dashboard__section-title">
                        {t(prefix + 'tournaments')} & Events
                    </div>
                    <div className="creative-dashboard__tournaments-holder">
                        {data.length ? (
                            data.map((tournament) => (
                                <div
                                    role="button"
                                    key={`tournament-key-${tournament.id}`}
                                    className="creative-dashboard__tournaments-card"
                                >
                                    <div className="d-flex justify-content-between align-items-start w-100">
                                        <div
                                            className="w-100"
                                            onClick={() => goToTournament(tournament.id)}
                                        >
                                            <div>
                                                <img
                                                    className="creative-dashboard__tournament-img"
                                                    src={
                                                        tournament.img ||
                                                        require('../../assets/images/noImage.png')
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            <div className="creative-dashboard__tournamentName">
                                                {tournament.name}
                                            </div>
                                            <div className="creative-dashboard__date">
                                                {tournament.startDate &&
                                                    getReadableDate(tournament.startDate)}
                                            </div>
                                        </div>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="link"
                                                className="p-1 border-0 shadow-none bg-white rounded-circle d-flex justify-content-center align-items-center"
                                            >
                                                <ThreeDotsVertical />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        copyTournament(tournament.id.toString())
                                                    }
                                                >
                                                    <Copy /> {t(prefix + 'copyTournament')}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        deleteTournament(tournament.id.toString())
                                                    }
                                                    disabled={deleteMutation.isPending}
                                                >
                                                    <Trash /> {t(prefix + 'deleteTournament')}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h4>{t(prefix + 'noTour')}</h4>
                        )}
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
};

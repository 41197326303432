import { getDateAsUtc } from './getStringDate';
import { Values } from '../pages/tourDetails/Information/components/InformationTag/InformationTag';

export const infoTagTypeConverter = (infoValues: Values) => {
    const customFieldNames = [
        'startDate',
        'endDate',
        'lastCancelationDate',
        'registrationOpen',
        'registrationClose',
    ];

    const dateValues = Object.entries(infoValues).reduce(
        (acc, [k, v]) =>
            v !== null && customFieldNames.includes(k) ? { ...acc, [k]: getDateAsUtc(v) } : acc,
        {}
    );

    const isFeatured = infoValues.isFeatured === true || infoValues.isFeatured === 'true';

    return {
        ...infoValues,
        ...dateValues,
        registrationFee:
            infoValues.registrationFee !== null ? Number(infoValues.registrationFee) : null,
        sections: infoValues.sections.map((section) => {
            if (typeof section.id === 'string') {
                return { title: section.title, description: section.description || null };
            } else {
                return section;
            }
        }),
        featureOrder: isFeatured ? Number(infoValues.featureOrder) : null,
    };
};

import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { CustomButton } from '../../../../../components';
import { uploadImage, UploadPath } from '../../../../../apis/tournament';

import './InfoImageUpload.scss';

interface InfoImageUploadProps {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    imgUrl: string | null;
    title?: string;
    settingData: {
        width?: string;
        height?: string;
        name: 'img' | 'icon' | 'featureImg' | string;
        type?: 'img' | 'icon' | 'featureImg';
    };
    savePath?: UploadPath;
    children?: JSX.Element;
    clearImage?: () => void;
    cropPreview?: {
        topPercentage: number;
        bottomPercentage: number;
    };
    disabled?: boolean;
}

export const InfoImageUpload: FC<InfoImageUploadProps> = ({
    setFieldValue,
    imgUrl,
    title,
    settingData,
    savePath,
    children,
    clearImage,
    cropPreview,
    disabled,
}) => {
    const [preview, setPreview] = useState(false);
    const { t } = useTranslation();
    const type = settingData.type || settingData.name;

    const prefix = 'creativeDetail-';
    const buttonPrefix = 'button-';

    const onChange = (imageList: ImageListType) => {
        const formData = new FormData();

        formData.append('file', imageList[0]?.file || '');
        imageList[0].file &&
            uploadImage(formData, savePath || 'tournament')
                .then((data) => {
                    setFieldValue(settingData.name, data.url);
                })
                .catch((e) => {
                    console.log(e);
                });
    };

    const renderPreviewBox = () => {
        if (cropPreview) {
            return (
                <>
                    {preview && !!cropPreview?.topPercentage && (
                        <div
                            className="previewBoxTop"
                            style={{
                                bottom: `${100 - cropPreview.topPercentage}%`,
                            }}
                        />
                    )}
                    {preview && !!cropPreview?.bottomPercentage && (
                        <div
                            className="previewBoxBottom"
                            style={{
                                top: `${100 - cropPreview.bottomPercentage}%`,
                            }}
                        />
                    )}
                </>
            );
        }
    };

    const imageType = useMemo(() => {
        switch (type) {
            case 'featureImg':
                return {
                    class: 'featureContainer',
                    alt: 'feature image',
                    text: t(prefix + 'image').toLowerCase(),
                };
            case 'icon':
                return {
                    class: 'iconContainer',
                    alt: 'tournament icon',
                    text: t(prefix + 'icon').toLowerCase(),
                };
            case 'img':
            default:
                return {
                    class: 'imgContainer',
                    alt: 'tournament image',
                    text: t(prefix + 'image').toLowerCase(),
                };
        }
    }, [t, type]);
    //TODO validation upload
    return (
        <ImageUploading
            // resolutionType="ratio"
            // resolutionWidth={1024}
            // resolutionHeight={1024}
            // onError={(errors, files) => {
            //     console.log(errors, files);
            // }}
            value={[]}
            onChange={onChange}
        >
            {({ onImageUpload }) => {
                return (
                    <div className="d-flex flex-column align-items-sm-center align-items-lg-start raqt-upload__wrapper">
                        <div className="m-auto d-flex flex-column w-sm-25 w-lg-100 align-items-center raqt-upload__image-wrapper">
                            <div>
                                {title && (
                                    <label
                                        htmlFor={settingData.name}
                                        className="font-weight-normal"
                                    >
                                        {title}
                                    </label>
                                )}
                                {imgUrl ? (
                                    <div
                                        className={`text-center position-relative ${imageType.class}`}
                                    >
                                        <img
                                            width={settingData.width || ''}
                                            height={settingData.height || ''}
                                            src={imgUrl}
                                            className={`${
                                                type === 'icon' ? 'rounded-circle' : 'rounded'
                                            } d-block`}
                                            alt={imageType.alt}
                                            style={{ objectFit: 'cover' }}
                                        />
                                        {renderPreviewBox()}
                                    </div>
                                ) : (
                                    <div
                                        className={`${imageType.class} d-flex justify-content-center align-items-center bg-dark text-white mb-2 position-relative`}
                                    >
                                        img
                                        {renderPreviewBox()}
                                    </div>
                                )}
                            </div>
                            {cropPreview && (
                                <>
                                    <p className="previewHelperText text-center">
                                        {t(prefix + 'previewHelperText')}
                                    </p>
                                    <button
                                        disabled={disabled}
                                        type="button"
                                        className={`previewCheckbox ${
                                            preview ? 'previewCheckboxSelected' : ''
                                        }`}
                                        onClick={() => setPreview((v) => !v)}
                                    >
                                        {preview
                                            ? t(prefix + 'hideGuidelines')
                                            : t(prefix + 'showGuidelines')}
                                    </button>
                                </>
                            )}

                            <div className="d-flex flex-row gap-1">
                                {!children && (
                                    <CustomButton
                                        disabled={disabled}
                                        btnText={`${t(buttonPrefix + 'change')} ${imageType.text}`}
                                        cb={onImageUpload}
                                    />
                                )}
                                {clearImage && (
                                    <CustomButton
                                        disabled={disabled}
                                        btnText={`${t(buttonPrefix + 'delete')} ${imageType.text}`}
                                        cb={clearImage}
                                    />
                                )}

                                {children && (
                                    <div onClick={onImageUpload} role="button">
                                        {children}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }}
        </ImageUploading>
    );
};

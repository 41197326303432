import { format } from 'date-fns';

export const getStringDateFormat = (date: string | null): string => {
    return format(new Date(date || Date.now()), `yyyy-MM-dd'T'HH:mm`);
};

export const getDateAsUtc = (date: Date | string) => {
    return (new Date(date)).toISOString();
}

export const getTime = (date: string | Date): string => {
    return format(new Date(date), `HH:mm`);
};

export const getDate = (date: string | Date): string => {
    return format(new Date(date), 'dd/MM yyyy');
};

export const getDateWithTime = (date: string | Date): string => {
    return format(new Date(date), 'dd/MM/yyyy hh:mm');
}

export const getReadableDate = (date: string | Date): string => {
    return format(new Date(date), 'EEEE dd MMM yyyy hh:mm');
}

export const getReadableForPublioDate = (date: string | Date): string => {
    return format(new Date(date), `EEEE dd MMM yyyy 'at' hh:mm`);
}
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton, FormTextField } from '../../../../../components';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { Section } from '../InformationTag/InformationTag';
import { ArrayHelpers, FieldArray, Field, FieldProps } from 'formik';
import { Accordion, Card, InputGroup } from 'react-bootstrap';
import { Editor } from '../Editor';
import uniqId from 'uniqid';
import { CustomAccordionHeader } from '../../../common/components/CustomAccordionHeader';
import { ReactSortable } from 'react-sortablejs';
import { GripHorizontal } from 'react-bootstrap-icons';

import './InfoSection.scss';

interface InfoSectionProps {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    placeholder?: string;
    data: Section[];
}

export const InfoSection: FC<InfoSectionProps> = ({ placeholder = '', data, setFieldValue }) => {
    const { t } = useTranslation();

    const prefix = 'creativeDetail-';
    const buttonPrefix = 'button-';
    const inputPrefix = 'input-';

    return (
        <FieldArray name="sections">
            {({ push, remove }: ArrayHelpers) => (
                <>
                    <div className="my-3 d-flex justify-content-between align-items-center">
                        <h4 className="font-weight-bold d-none d-lg-block">
                            {t(prefix + 'infoSection')}
                        </h4>
                        <h4 className="font-weight-bold d-md-block d-sm-block d-lg-none">
                            {t(prefix + 'info')}
                        </h4>
                        <CustomButton
                            variant="outline-dark"
                            btnText={t(buttonPrefix + 'addSection')}
                            cb={() => push({ id: uniqId(), title: '', description: '' })}
                            className="d-flex justify-content-between align-items-center"
                        >
                            <AddIcon />
                        </CustomButton>
                    </div>
                    {data.length ? (
                        <ReactSortable
                            list={data.map((section) => ({
                                id: section.id ?? '',
                                title: section.title,
                                description: section.description,
                            }))}
                            setList={(newState) => {
                                setFieldValue('sections', newState);
                            }}
                            animation={200}
                            handle=".drag-handle"
                        >
                            {data.map((section, index) => (
                                <div key={section.id} className="container-fluid px-0 my-3">
                                    <Accordion className="my-3">
                                        <Card>
                                            <Card.Header className="p-0 bg-white border-0">
                                                <CustomAccordionHeader eventKey={index.toString()}>
                                                    <InputGroup className="d-flex flex-row flex-nowrap align-items-center">
                                                        <div className="drag-handle px-2 cursor-grab">
                                                            <GripHorizontal />
                                                        </div>
                                                        <FormTextField
                                                            className="py-0 border-1 w-100 border-white"
                                                            index={index}
                                                            maxLength={50}
                                                            name={`sections.${index}.title`}
                                                            placeholder={
                                                                placeholder ||
                                                                t(
                                                                    inputPrefix +
                                                                        'placeholderTitleSection'
                                                                )
                                                            }
                                                            type="text"
                                                        />
                                                    </InputGroup>
                                                </CustomAccordionHeader>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={index.toString()}>
                                                <Card.Body className="p-2">
                                                    <Field name={`sections.${index}.description`}>
                                                        {({ field }: FieldProps) => (
                                                            <Editor
                                                                value={field.value}
                                                                cb={field.onChange(field.name)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <div className="d-flex justify-content-end pt-3">
                                                        <CustomButton
                                                            className="d-flex align-items-center"
                                                            btnText={t(
                                                                buttonPrefix + 'removeSection'
                                                            )}
                                                            variant="outline-dark"
                                                            onClick={() => {
                                                                remove(index);
                                                            }}
                                                        >
                                                            <CloseCircle />
                                                        </CustomButton>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            ))}
                        </ReactSortable>
                    ) : null}
                </>
            )}
        </FieldArray>
    );
};

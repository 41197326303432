import { api } from './api.config';

import {
    TournamentState,
    User,
    Group,
    Match,
    MatchResult,
    Class,
    Tour,
    Court,
} from '../types/tournament';
import { TournamentsState } from '../features/tournaments/tournamentsSlice';
import { initialTournamentData } from '../constant/tournament';
import { ImageFile } from '../types/uploadFile';
import { TournamentMatchStatusEnum } from '../types/enums';
import {
    IMatchScheduleItem,
    IScheduleConstraints,
    ScheduleAndUnscheduledMatches,
} from '../types/schedule';
import { ClassTeam } from '../pages/tourDetails/TournamentClasses/components/ClassesTag/ClassesTag';

export interface IStream {
    id: number;
    courtId: number;
    startDate: Date;
    endDate: Date;
    category: string;
    name: string;
    description: string;
    image: string;
    url: string;
}

export interface ITournamentStats {
    totalClasses: number;
    totalPlayers: number;
    totalTickets: number;
    totalMatches: number;
}

export interface IMatchBoardItem {
    id: number;
    tournamentId: number;
    matchIdToShow: string;
    startDate: string;
    endDate: string;
    courtName: string;
    awayPlayer1: string;
    awayPlayer2: string | null;
    awayTeamName: string;
    homePlayer1: string;
    homePlayer2: string | null;
    homeTeamName: string;
    tournamentClassName: string;
    results: string[];
    status: TournamentMatchStatusEnum;
}

export interface IGetMatchBoardForTournament {
    matchBoard: IMatchBoardItem[];
    total: number;
}

interface IGetMatchBoardForTournamentParams {
    id: number;
    search?: string;
    take?: number;
    skip?: number;
    classId?: number | null;
}

interface IPlayersInfoAPI {
    playersInfo: IPlayersInfo[];
}

export interface IPlayersInfo {
    id: number;
    name: string;
    teams: { name: string; seed: number }[];
}

interface IClassesInfoAPI {
    classesInfo: IClassesInfo[];
}

export interface IClassesInfo {
    currency: string;
    maxPlayers: number;
    name: string;
    price: number;
    registered: number;
    vat: number;
}

export interface ITicket {
    class: string;
    currency: string;
    date: string | Date;
    paymentId: string;
    price: number;
    purchasedTicket: string;
    vat: number;
}

export interface ITicketSummery {
    currency: string;
    invoice: number;
    serviceFee: string;
    serviceFeePrice: string;
    ticketsAmount: string | number;
    ticketsPrice: number;
    vat: string;
    vatIncludedPrice: string;
}

export interface IRaqtInvoice {
    id: number;
    invoiceAddress: string;
    invoiceEmail: string;
    organizationNumber: string;
    raqtVAT: string;
}

export interface IFetchTicketsInfoAPI {
    ticketsInfo: ITicket[];
    ticketsSummery: ITicketSummery[];
    raqtDetails: IRaqtInvoice;
}

export interface IPublicTournament {
    id: number;
    name: string;
    description: string;
    startDate: Date | string;
    endDate: Date | string;
    registrationOpen: Date | string;
    registrationClose: Date | string;
    location: string;
    img: string;
    icon: string;
    featureImg: string;
    creator: ICreator;
    sections: ISection[];
}

export interface ICreator {
    firstName: string;
    lastName: string;
}

export interface ISection {
    id: number;
    title: string;
    description: string;
}

export interface IMatchBoardGroupedByClass {
    [classId: number]: IMatchScheduleItem[];
}

export interface IMatchScheduleItemDto {
    id: number;
    scheduleDate: Date;
    court: Court;
}

export type UploadPath =
    | 'tournament'
    | 'team'
    | 'organization'
    | 'sponsor'
    | 'paddle'
    | 'avatar'
    | 'logo';

export const fetchPublicTournamentAPI = async (): Promise<TournamentState> => {
    const response = await api.get('tournament/tournaments-short-info');
    return response.data;
};

export const fetchStreamsAPI = async (): Promise<IStream[]> => {
    const response = await api.get('tournament/raqt-tv');
    return response.data;
};

export const fetchTicketsInfoAPI = async (id: number): Promise<IFetchTicketsInfoAPI> => {
    const response = await api.get(`tournament/${id}/tickets-info`);
    return response.data;
};

export const fetchPlayersInfoAPI = async (id: number): Promise<IPlayersInfoAPI> => {
    const response = await api.get(`tournament/${id}/players-info`);
    return response.data;
};

export const fetchClassesInfoAPI = async (id: number): Promise<IClassesInfoAPI> => {
    const response = await api.get(`tournament/${id}/classes-info`);
    return response.data;
};

export const fetchTournamentData = async (id: number): Promise<TournamentState> => {
    const response = await api.get(`tournament/${id}`);
    return response.data;
};

export const fetchPublicViewTournamentAPI = async (id: number): Promise<IPublicTournament> => {
    const response = await api.get(`tournament/${id}/view`);
    return response.data;
};

export const fetchTournamentInfoData = async (id: number): Promise<TournamentState> => {
    const response = await api.get(`tournament/${id}/tournament-info`);
    return response.data;
};

export const fetchTournamentStatsAPI = async (id: number): Promise<ITournamentStats> => {
    const response = await api.get(`tournament/${id}/summery`);
    return response.data;
};

export const fetchClassesByTournamentId = async (id: number): Promise<Array<Class>> => {
    const response = await api.get(`tournament/${id}/classes`);
    return response.data;
};

export const fetchClassesByTournamentIdAndClassId = async (classId: number): Promise<Class> => {
    const response = await api.get(`tournament/class/${classId}`);
    return response.data;
};

export const fetchClassListByTournamentId = async (id: number): Promise<Array<Class>> => {
    const response = await api.get(`tournament/${id}/classes-list`);
    return response.data;
};

export const fetchTournamentWithClassListByTournamentId = async (
    id: number
): Promise<TournamentState> => {
    const response = await api.get(`tournament/${id}/with-class-list`);
    return response.data;
};

export const fetchTournamentWithClassAndCourtListByTournamentId = async (
    id: number
): Promise<TournamentState> => {
    const response = await api.get(`tournament/${id}/info-with-class-and-court-list`);
    return response.data;
};

export const fetchClassStandingsByClassId = async (id: number): Promise<Array<Class>> => {
    const response = await api.get(`tournament/class/${id}/standings`);
    return response.data;
};

export const fetchTournamentsData = async (
    organizationId: number | string
): Promise<Array<TournamentsState>> => {
    const response = await api.get('tournament', { params: { organization: organizationId } });
    return response.data;
};

export const updateTournamentData = async (
    id: number,
    organization: number,
    data: any
): Promise<TournamentState> => {
    // TODO: temporary fields: organization & creator, because out of registration
    const response = await api.patch<TournamentState>(`tournament/${id}`, {
        ...data,
        organization,
    });
    return response.data;
};
export const updateTournamentInfoData = async (
    id: number,
    organization: number,
    data: any
): Promise<TournamentState> => {
    const response = await api.patch<TournamentState>(`tournament/${id}/tournament-info`, {
        ...data,
        organization,
    });
    return response.data;
};

export const fetchUsersData = async (userName: string = '') => {
    const response = await api.get<User[]>('user/search', {
        params: { search: userName },
    });
    return response.data;
};

export const fetchTeamsData = async (communityId: string, searchTerm: string = '') => {
    const response = await api.get<ClassTeam[]>('user/search-teams', {
        params: { limit: 10, organizationId: Number(communityId), searchTerm },
    });
    return response.data;
};

export const updatePlayData = async (
    classId: number,
    data: any
): Promise<{ id: number; tournamentGroups: Group[]; tournamentMatches: Match[] }> => {
    const response = await api.patch(`tournament/class-play/${classId}`, data);
    return response.data;
};

export const updatePlayStatus = async (
    matchId: number,
    data: { status: number }
): Promise<Match> => {
    const response = await api.patch(`tournament/match-status/${matchId}`, data);
    return response.data;
};

export const updateMatchStatusData = async (
    matchId: number,
    data: { results: Array<MatchResult> }
): Promise<{
    id: number;
    results: Array<MatchResult>;
}> => {
    const response = await api.patch(`tournament/match-result/${matchId}`, data);
    return response.data;
};

export const createTournament = async (organizationId: number): Promise<TournamentState> => {
    const response = await api.post<TournamentState>('tournament', {
        ...initialTournamentData,
        organization: organizationId,
    });
    return response.data;
};

export const duplicateTournament = async (id: number): Promise<TournamentState> => {
    const response = await api.post<TournamentState>(`tournament/duplicate/${id}`);
    return response.data;
};

export const deleteTournament = async (id: number): Promise<{ affected: 0 | 1; id: number }> => {
    const response = await api.delete<{ affected: 0 | 1 }>(`tournament/${id}`);
    return { ...response.data, id };
};

export const safeDeleteTournament = async (
    id: number
): Promise<{ affected: 0 | 1; id: number }> => {
    const response = await api.delete<{ affected: 0 | 1 }>(`tournament/safe-delete/${id}`);
    return { ...response.data, id };
};

export const uploadImage = async (data: FormData, name: UploadPath): Promise<ImageFile> => {
    const response = await api({
        method: 'post',
        url: `upload/${name}`,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
};

export const generateMatchesData = async (classId: number): Promise<Class> => {
    const response = await api.post(`tournament/class-generate-matches/${classId}`);
    return response.data;
};

export const getListTour = async (): Promise<Tour[]> => {
    const response = await api.get(`tour`);
    return response.data;
};

export const generateGroupsRequest = async (classId: number): Promise<any> => {
    const response = await api.post(`tournament/class-generate-groups/${classId}`);
    return response.data;
};

export const generateCategoriesByClass = async (classId: number): Promise<any> => {
    const response = await api.post(`tournament/class-generate-categories/${classId}`);
    return response.data;
};

export const bulkDeleteGroupsByClass = async (classId: number): Promise<any> => {
    const response = await api.delete(`tournament/class-bulk-delete-by-id/${classId}`);
    return response.data;
};

export const getMatchBoardForTournament = async ({
    id,
    search = '',
    take,
    skip,
    classId,
}: IGetMatchBoardForTournamentParams): Promise<IGetMatchBoardForTournament> => {
    const response = await api.get(`tournament/${id}/match-board`, {
        params: {
            search: search.toLocaleLowerCase(),
            take,
            skip,
            classId,
        },
    });
    return response.data;
};

export const getMatchBoardGroupedByClassForTournament = async (
    id: number
): Promise<IMatchBoardGroupedByClass> => {
    const response = await api.get(`tournament/${id}/match-board-grouped-by-class`);
    return response.data;
};

export const createTournamentSchedule = async (
    id: number,
    constraints: IScheduleConstraints
): Promise<ScheduleAndUnscheduledMatches> => {
    const response = await api.post(`tournament/${id}/create-match-schedule`, constraints);
    return response.data;
};

export const getTournamentSchedule = async (id: number): Promise<ScheduleAndUnscheduledMatches> => {
    const response = await api.get(`tournament/${id}/get-match-schedule`);
    return response.data;
};

export const updateTournamentSchedule = async (
    id: number,
    constraints: IScheduleConstraints,
    matchScheduleItems: IMatchScheduleItemDto[]
): Promise<any> => {
    return await api.patch(`tournament/${id}/update-match-schedule`, {
        matchScheduleItems,
        constraints,
    });
};

export const publishTournamentSchedule = async (
    id: number,
    constraints: IScheduleConstraints,
    matchScheduleItems: IMatchScheduleItemDto[]
): Promise<ScheduleAndUnscheduledMatches> => {
    const response = await api.post(`tournament/${id}/publish-schedule`, {
        matchScheduleItems,
        constraints,
    });
    return response.data;
};

export const getScheduleConstraints = async (id: number): Promise<IScheduleConstraints> => {
    const response = await api.get(`tournament/${id}/get-schedule-constraints`);
    return response.data;
};

export const removeTournamentSchedule = async (id: number): Promise<any> => {
    return await api.patch(`tournament/${id}/remove-match-schedule`);
};
